import React, { Component } from 'react'
import { Paper,Box,Link, Checkbox } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import map2 from "../src/img/map2.png"
import moment from 'moment'
import firebase from '../src/firebase'
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    Enquiry
    </Typography>,
  ];

export class Enquiry extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       name : "",
       email  :"",
       phone_no : "",
       desc  :"",
       phone_no_2 : "",
       
       message : ""

    }
    this.handleChange=this.handleChange.bind(this);
  
  }

    
    
    handleChange=(e)=>{
    this.setState({[e.target.name]:e.target.value})
    }
    




sendInquires=()=>{
  if( this.state.name!==""&&this.state.email!==""&&this.state.phone_no!==""&&this.state.phone_no_2!==""&&this.state.desc!==""){
  const key= firebase.database().ref("contactform").push().key
  firebase.database().ref("leads_data").child(key).set({
name:this.state.name,
email  :this.state.email,
phone_no : this.state.phone_no,
desc  :this.state.desc,
phone_no_2 : this.state.phone_no_2,
date  : moment().format('l'),
key:key   
  }).then(()=>{
    this.setState({
      name : "",
      email  :"",
      phone_no : "",
      desc  :"",
      phone_no_2 : "",
    },()=>{
    this.setState({
      message : "Thank you!"
    })
    })
  })
}else{
  alert("please fill all fields")
}
  
}


  render() {
    return (
      <div>
    
<br/>
<br/>
<br/>
 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},borderRadius:1}}>
 <Container maxWidth='xl'>
 <br/>
 <Grid container spacing={2}>
 <Grid item xs={12} sm={12}>
 <Box sx={{backgroundColor:'white',minHeight:400,display:'flex',flexDirection:'column',marginLeft:{xs:'2%',sm:'25%',md:'35%'},mr:{xs:'2%',sm:'25%',md:'35%'},borderRadius:1}}>
 <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:'0',sm:'0',md:'4%',},marginRight:{xs:'0',sm:'0',md:'4%',}}}>
 <br/>
 <Typography align='center' sx={{m:2,fontWeight:'bold',color:'#004285'}}>Are You Looking For Softwares</Typography>
 <Divider/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Name</Typography>
 <TextField InputProps={{style:{fontSize:12}}}  value={this.state.name}  onChange={this.handleChange} id="outlined-basic" placeholder="Name" name='name' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Email Id</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.email} onChange={this.handleChange} id="outlined-basic" placeholder="Enter email id" name='email' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Phone No 1</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.phone_no} onChange={this.handleChange} id="outlined-basic" placeholder="Enter phone no 1" name='phone_no' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Phone No 2</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.phone_no_2} onChange={this.handleChange} id="outlined-basic" placeholder="Enter phone no 2" name='phone_no_2' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Describe your requirements</Typography>
 <TextField InputProps={{style:{fontSize:12}}} minRows={3} value={this.state.desc} onChange={this.handleChange} id="outlined-basic" placeholder="Describe your requirements" name='desc' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>





<br/>


 <Button variant="contained" onClick={this.sendInquires} sx={{width:200,backgroundColor:'green',ml:2}}>Submit details</Button>
<br/>
<br/>
<Typography sx={{fontsize:"20",fontweight:'bold',color:'red'}} >{this.state.message}</Typography>

 <br/>
 <br/>
 </Box> 
   



   </Box>
 </Grid>
</Grid>

</Container>
</Box>
<br/>
 </Box>
 
 





      
      </div>
    )
  }
}

export default Enquiry


